import React, { useState, useContext, useEffect } from 'react';
import styled from '@emotion/styled';
import useSound from 'use-sound';
import { NavLink } from 'react-router-dom';
import { parseISO, differenceInSeconds } from 'date-fns';
import { Menu, useDisclosure } from '@chakra-ui/core';
import { ProTrialBanner } from 'src/modules/account/ProtrialBanner';
import UploadDropdown from 'src/modules/controls/components/UploadDropdown';
import { workspaceContext, useUserContext } from 'src/context';
import HelpButton from 'src/design-system/elements/buttons/HelpButton';
import notificationSound from 'src/design-system/sizle-notification.mp3';

const PageTitle = () => {
  const { user, workspaceIndex, hideCreateTooltip, setHideCreateTooltip, avatarCacheTimestamp } = useUserContext();
  const { workspacePermissions, notifications } = useContext(workspaceContext);
  const [hasRecentNotification, setHasRecentNotification] = useState(false);
  const [previousNotifications, setPreviousNotifications] = useState([]);
  const { isOpen: isMenuOpen, onToggle, onOpen: onOpenMenu, onClose: onMenuClose } = useDisclosure();
  const [play] = useSound(notificationSound);
  const [bump, setBump] = useState(false);

  // Combine notification effect
  useEffect(() => {
    if (notifications?.length > 0) {
      const latestNotification = notifications[0];
      const seconds = Math.abs(differenceInSeconds(parseISO(latestNotification.created_at), new Date()));
      if (seconds < 10) {
        setHasRecentNotification(true);
        if (previousNotifications[0] !== latestNotification) {
          setPreviousNotifications(notifications);
          play();
        }
      } else {
        setHasRecentNotification(false);
      }
    }
  }, [notifications, previousNotifications, play]);

  const handleMenuToggle = () => {
    onToggle();
    setHideCreateTooltip(true);
  };

  // Bump animation trigger
  useEffect(() => {
    const interval = setInterval(() => {
      setBump(true);
      setTimeout(() => setBump(false), 500); // Reset animation after duration
    }, 10000); // Trigger every 30 seconds

    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, []);

  return (
    <div className='page-title'>
      <div className='mobile-protrial-banner-container'>
        <ProTrialBanner />
      </div>

      <div className='page-title-container'>
        <div name='upload-dropdown' className={`hide-hint upload-dropdown ${hideCreateTooltip ? 'immediate' : ''}`}>
          <Menu closeOnBlur closeOnSelect={false} autoSelect={false} isOpen={isMenuOpen}>
            <Cover hidden={!isMenuOpen} onClick={onMenuClose} />
            <StyledButton
              className={`btn ${bump ? 'bump' : ''}`}
              onClick={handleMenuToggle}
              disabled={!workspacePermissions.includes('Add & Edit Documents')}
              title='Click to upload or import a file'
            >
              Upload document
            </StyledButton>
            <UploadDropdown onOpen={onOpenMenu} onClose={onMenuClose} />
          </Menu>
        </div>
        <div className='protrial-banner-container'>
          <ProTrialBanner />
        </div>
        <HelpButton />
        <button
          className='btn'
          disabled={!workspacePermissions.includes('Add & Edit Documents')}
          title='Click to view plan details'
        >
          <a href={`/s/${workspaceIndex}/settings`}>Add team members</a>
        </button>
        <StyledUserProfile>
          <StyledUserProfileLink to={workspaceIndex !== null ? `/s/${workspaceIndex}/account` : '/account'}>
            <img
              className='avatar-small'
              src={`/api/users/${user?.sub}/avatar?timestamp=${avatarCacheTimestamp.getMilliseconds()}`}
              alt='User Profile'
            />
          </StyledUserProfileLink>
        </StyledUserProfile>
      </div>
    </div>
  );
};

const StyledButton = styled.button`
  transition: transform 0.2s ease-in-out;

  &.bump {
    animation: bump 0.5s ease-in-out;
  }

  @keyframes bump {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.1);
    }
    100% {
      transform: scale(1);
    }
  }
`;

const StyledUserProfile = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const StyledUserProfileLink = styled(NavLink)`
  display: flex;
  align-items: center;
  justify-content: center;
  > img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }
`;

const Cover = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100vw;
  z-index: 10;
`;

export default PageTitle;
